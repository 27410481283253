<template>
  <div class="topic">
      <BackBar :pageTit="headTit" />
    <vue-scroll class="main-page" :ops="ops"
      @refresh-start="handleRefresh"
      @load-start="handleLoad"
    >
      <ul>
        <li v-for="item in posts" :key="item" >
            <div class="prize_fl">
                <div class="num">{{item.no}}</div>
                <div class="people_info">
                    <div class="tx"><img :src="item.user.headimgurl"></div>
                    <div class="p_fr">
                        <div>{{item.user.title}}</div>
                        <p>积分：{{item.goal}}</p>
                    </div>
                </div>
            </div>
            <img src="~@/assets/img/jt_fr.png" @click="$router.push('/user/' + item.user.id)" class="prize_fr">
        </li>
    </ul>
    </vue-scroll>
    
  </div>
</template>

<script>
import BackBar from '@/components/BackBar.vue'
import Api from '@/api'
import Vue from 'vue'
import PostItem from '@/components/PostItem.vue'

export default {
  name: 'User',
  data(){
    const ops = {
      vuescroll: {
        mode: 'slide',
        pullRefresh: {
          enable: true
        },
        pushLoad: {
          enable: true,
        }
      }
    };
   
      ops.vuescroll.pullRefresh.tips = {
        deactive: '下拉刷新',
        active: '释放刷新',
        start: '刷新中...',
        beforeDeactive: '刷新成功!'
      };
      ops.vuescroll.pushLoad.tips = {
        deactive: '上拉加载',
        active: '释放加载',
        start: '加载中...',
        beforeDeactive: '加载成功!'
      };
    

    return {
        posts:[],
        page:1,
        ops:ops,
        isloading:false,
        headTit:'积分排行榜'
 
    }
  },
  components: {
    BackBar,
    PostItem
  },
  methods:{
    handleRefresh(vm, refreshDom, done){
      if(this.isloading){return;}
      console.log('refresh')
      this.page=1;
      this.posts=[];
      this.refreshPost(done)
    },
    handleLoad(vm, refreshDom, done){
      if(this.isloading){return;}
      console.log('handleLoad')
      this.refreshPost(done)
    },
    refreshPost(cb){
      var that=this
      this.isloading=true
      
      Api.go('hot',{page:this.page++},(res)=>{
        var _=that.posts
        if(res.length>0){
          for(var i in res){
            _.push(res[i])
          }
        }else{
          Vue.prototype.$toast('暂无更多')
        }
        if(cb){cb();}
        that.isloading=false;
      }) 
    }
  },
  mounted(){
      
    this.refreshPost()
  }
}
</script>

<style lang="less" scoped>
.topic{
    ul{
        background: #fff;
        padding: 0 .32rem;
        li{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: .24rem 0;
            &:nth-child(1) .prize_fl .num{
                width: .48rem;
                height: .42rem;
                background: url(~@/assets/img/prize1.png) no-repeat center center/cover;
                text-indent: 99999px;
            }
            &:nth-child(2) .prize_fl .num{
                width: .48rem;
                height: .42rem;
                background: url(~@/assets/img/prize2.png) no-repeat center center/cover;
                text-indent: 99999px;
            }
            &:nth-child(3) .prize_fl .num{
                width: .48rem;
                height: .42rem;
                background: url(~@/assets/img/prize3.png) no-repeat center center/cover;
                text-indent: 99999px;
            }
        }
    }
    .prize_fl{
        display: flex;
        align-items: center;
        .num{
            width: .48rem;
            font-size: .28rem;
            line-height: .58rem;
            color: #666;
            font-weight: 600;
            text-align: center;
            margin-right: .42rem;
        }
        .people_info{
            display: flex;
            align-items: center;
            .tx{
                width: .76rem;
                height: .76rem;
                background: #999;
                border-radius: 50%;
                overflow: hidden;
                margin-right: .2rem;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .p_fr{
                div{
                    font-size: .28rem;
                    color: #333;
                    font-weight: 400;
                }
                p{
                    font-size: .24rem;
                    color: #999;
                    font-weight: 400;
                }
            }
        }
    }
    .prize_fr{
        width: .32rem;
        height: .32rem;
    }
}
   
/* .topic{padding-bottom: 1rem;position: fixed;left: 0;top:0;width: 100%;height: 100%; box-sizing: border-box;}
.topic h2{line-height: 1rem;text-align: center;}
.newpost-btn{position: fixed;display: block;
bottom: 1.4rem;right: 0.2rem;height: 1.14rem;width:1.14rem;
background: url(~@/assets/img/home-add.png) no-repeat center center/cover;}
.user-box{
background: #F3C878;
border-radius: 8px;padding:0.4rem;text-align: center;
}
.user-box .avatar{height: 1rem;margin:0 auto 0.4rem auto;width: 1rem;
border-radius: 1rem;background-size: cover;}
.user-wp{background:rgb(248,248,250);padding:0.4rem;} */
</style>